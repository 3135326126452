<template>
	<div class="c-site-footer">
		<div class="relative h-80 >=1024:h-180 overflow-hidden">
			<!-- Lens Flare Desktop -->
			<LensFlare
				id="c-site-footer__flare-desktop"
				:class="[
					'absolute top-1/2 left-1/2 w-visual-screen max-w-layout-max',
					'transform -translate-x-1/2 -translate-y-1/2',
					'hidden >=1024:block',
				]"
				:angle="4"
				:min-blur-amount="5"
				:max-blur-amount="30"
				:flares="[
					{
						size: 525,
						offset: 0,
						targetOffset: -150,
						theme: 'primary',
						opacity: 1,
					},
					{
						size: 355,
						targetSize: 30,
						offset: -200,
						targetOffset: -640,
						theme: 'secondary',
						opacity: 0.8,
					},
					{
						size: 235,
						targetSize: 180,
						offset: 230,
						targetOffset: 570,
						theme: 'secondary',
						opacity: 0.5,
					},
					{
						size: 135,
						targetSize: 0,
						offset: 230,
						targetOffset: 1230,
						theme: 'secondary',
						opacity: 0.5,
					},
				]"
			/>

			<!-- Lens Flare Mobile -->
			<LensFlare
				id="c-site-footer__flare-mobile"
				:class="[
					'absolute top-1/2 left-1/2 w-visual-screen ',
					'transform -translate-x-1/2 -translate-y-1/2',
					'>=1024:hidden',
				]"
				:angle="15"
				:min-blur-amount="15"
				:max-blur-amount="25"
				:flares="[
					{
						size: 625,
						offset: -50,
						targetOffset: 50,
						theme: 'primary',
						opacity: 1,
					},
					{
						size: 375,
						targetSize: 150,
						offset: -300,
						targetOffset: -450,
						theme: 'secondary',
						opacity: 0.8,
					},
					{
						size: 285,
						targetSize: 150,
						offset: 250,
						targetOffset: 650,
						theme: 'secondary',
						opacity: 0.5,
					},
				]"
			/>
		</div>

		<footer
			id="footer"
			:class="[
				showPartners ? 'pb-64' : 'pb-95 >=1024:pb-120',
				'max-w-layout-max px-layout-margin mx-auto flex flex-col items-start >=1024:grid >=1024:grid-cols-12 >=1024:gap-x-layout-gutter >=1024:gap-y-lg pt-4xl',
			]"
		>
			<!-- First Column -->
			<div class="order-first row-start-1 col-start-1 col-end-13">
				<SiteLogo
					:to="siteUrl"
					class="w-full h-auto"
					:max-width="360"
					:max-height="54"
				/>
			</div>

			<div
				v-if="address"
				class="
					order-1
					row-start-2
					col-start-1 col-end-5
					mt-2xl
					>=1024:mt-0
				"
			>
				<BaseH3
					v-if="siteName !== null"
					class="mb-32"
					tag="h2"
					v-text="siteName"
				/>
				<div>
					{{ address.street }}<br />
					{{ address.postalCode }} {{ address.city }}
				</div>

				<div v-if="address.phone || address.email" class="mt-lg">
					<a
						v-if="address.phone"
						class="block"
						:href="`tel:${address.phone}`"
					>
						{{ address.phone }}
					</a>
					<a
						v-if="address.email"
						class="block"
						:href="`mailto:${address.email}`"
					>
						{{ address.email }}
					</a>
				</div>
			</div>

			<div
				v-if="soMeItems"
				class="
					order-4
					row-start-4
					col-start-1 col-end-10
					mt-32
					>=1024:mt-0 >=1024:self-center
				"
			>
				<div class="flex space-x-12">
					<a
						v-for="({ serviceName, url, icon }, index) in soMeItems"
						:key="`some-${index}`"
						:href="url"
						:aria-label="`gå til ${serviceName}`"
					>
						<Component :is="icon" class="text-text w-32" />
					</a>
				</div>
			</div>

			<!-- Second Column -->
			<template v-if="seeAlso">
				<div
					v-if="seeAlso.links && seeAlso.links.length"
					class="
						seealso
						w-full
						order-2
						row-start-2
						col-start-5 col-end-9
						mt-32
						pt-32
						>=1024:pt-0 >=1024:mt-0
					"
				>
					<BaseH3
						v-if="seeAlso.title"
						class="mb-32"
						tag="h2"
						v-text="seeAlso.title"
					/>
					<div
						v-if="seeAlso.links && seeAlso.links.length"
						:class="[
							'>=1024:mt-0',
							{
								'mt-5xl': !seeAlso.title,
								'mt-xl': seeAlso.title,
							},
						]"
					>
						<div class="space-y-8">
							<NuxtLinkExt
								v-for="(link, index) in seeAlso.links"
								:key="`see-also-link-${index}`"
								:to="link.url"
								:target="link.target"
								:class="[
									'c-site-footer__link pr-8',
									'flex items-start space-x-16',
									'text-button font-medium font-darker-grotesque',
									'group',
								]"
							>
								<SvgIconArrow
									:class="[
										'flex-shrink-0',
										'mt-4 w-16 text-text',

										{
											'transform -rotate-45':
												link.url === '_blank' ||
												isExternal(link.url),
										},
									]"
								/>

								<div
									:class="[
										'mb-4',
										'duration-500 ease-smooth-out',
										'transform group-hover:translate-x-8',
									]"
									v-text="link.name"
								></div>
							</NuxtLinkExt>
						</div>
					</div>
				</div>

				<div
					v-if="seeAlso.readOutLoud || !isSystemReducedMotion"
					class="
						order-5
						flex flex-col
						row-start-4
						col-start-10 col-end-13
						w-full
						mt-32
						>=1024:mt-0 >=1024:self-center >=1024:items-end
						space-y-8
					"
				>
					<NuxtLinkExt
						:to="seeAlso.readOutLoud.url"
						:target="seeAlso.readOutLoud.target"
						:class="[
							'flex items-center space-x-12',
							'text-button font-medium font-darker-grotesque',
							'group pr-8',
						]"
					>
						<SvgIconSpeaker class="flex-shrink-0 w-18" />
						<div
							:class="[
								'mb-4',
								'duration-500 ease-smooth-out',
								'transform group-hover:translate-x-8',
							]"
							v-text="'Læs højt'"
						></div>
					</NuxtLinkExt>
					<button
						v-if="!isSystemReducedMotion"
						:class="[
							'flex items-center space-x-12',
							'text-button font-medium font-darker-grotesque',
							'group pr-8',
						]"
						@click="toggleAnimation"
					>
						<SvgIconPlay
							v-if="isReducedMotion"
							class="flex-shrink-0 w-18"
						/>
						<SvgIconPause v-else class="flex-shrink-0 w-18" />
						<div
							:class="[
								'mb-4',
								'duration-500 ease-smooth-out',
								'transform group-hover:translate-x-8',
							]"
							v-text="
								isReducedMotion
									? 'Slå animeret pynt til'
									: 'Slå animeret pynt fra'
							"
						></div>
					</button>
				</div>
			</template>

			<div class="spacer row-start-3 col-start-1 col-end-13"></div>

			<!-- Third Column -->
			<div
				v-if="showCityOfArms"
				class="
					cityofarms
					w-full
					order-3
					flex flex-col
					row-start-2 row-end-4
					col-start-10 col-end-13
					mt-3xl
					pt-32
					>=1024:items-end >=1024:mt-0 >=1024:pt-0
				"
			>
				<div class="inline-block">
					<BaseH3
						v-if="showCityOfArms && titleCityOfArms !== null"
						class="mb-32"
						tag="h2"
						v-text="titleCityOfArms"
					/>
					<a
						class="inline-block w-auto h-72 >=1024:h-80"
						style="aspect-ratio: 147 / 57"
						href="https://odsherred.dk/"
						target="_blank"
						aria-label="til Odsherred Kommunes hjemmeside"
					>
						<SvgLogo class="w-auto h-full" />
					</a>
				</div>
			</div>

			<!-- Partners -->
		</footer>
		<div v-if="showPartners" class="partners w-screen pb-95 >=1024:pb-105">
			<div
				class="
					flex flex-col
					items-start
					max-w-layout-max
					px-layout-margin
					mx-auto
					pt-64
					>=1024:items-center
				"
			>
				<BaseH3
					v-if="partners.title"
					tag="h2"
					class="mb-32"
					v-text="partners.title"
				/>
				<div
					class="
						flex
						items-center
						flex-col
						w-full
						space-y-16
						>=1024:w-auto
						>=1024:flex-row
						>=1024:space-x-16
						>=1024:space-y-0
					"
				>
					<a
						v-for="{ content: partner } in partners.items"
						:key="partner.key"
						class="
							c-site-footer__partner
							text-center
							w-full
							>=1024:w-145
						"
						:href="partnerLinkUnwrap(partner).url"
						:target="partnerLinkUnwrap(partner).target"
					>
						<figure
							class="
								flex
								space-x-16
								items-center
								>=1024:space-x-0 >=1024:flex-col
							"
						>
							<UmbracoImage
								v-if="partner.partnerlogo"
								class="
									w-48
									h-48
									object-contain
									aspect-square
									>=1024:w-80 >=1024:h-80
								"
								:widths="[80]"
								:sizes="
									imageSizes({
										default: '100vw',
									})
								"
								:alt="partner.partnerlogo.altText"
								:source-url="partner.partnerlogo.cropUrl"
								:source-width="partner.partnerlogo.width"
								:source-height="partner.partnerlogo.height"
								:lazyload="false"
							/>
							<figcaption
								v-if="partner.partnerName"
								class="
									font-darker-grotesque font-medium
									text-button
									leading-1
									>=1024:mt-8
								"
								v-text="partner.partnerName"
							></figcaption>
						</figure>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UmbracoImage, { imageSizes } from '@limbo-works/vue-umbraco-image';
import { mapState } from 'vuex';

import SiteLogo from '~/components/subsite/SiteLogo';
import LensFlare from '~/components/shared/LensFlare';
import SvgLogo from '~/assets/svgs/logo.svg?inline';
import SvgIconArrow from '~/assets/svgs/icon-arrow.svg?inline';
import SvgIconPlay from '~/assets/svgs/icon-play.svg?inline';
import SvgIconPause from '~/assets/svgs/icon-pause.svg?inline';
import SvgIconFacebook from '~/assets/svgs/icon-facebook.svg?inline';
import SvgIconInstagram from '~/assets/svgs/icon-instagram.svg?inline';
import SvgIconLinkedIn from '~/assets/svgs/icon-linkedin.svg?inline';
import SvgIconTwitter from '~/assets/svgs/icon-twitter.svg?inline';
import SvgIconSpeaker from '~/assets/svgs/icon-speaker.svg?inline';

const iconMap = {
	facebookUrl: 'SvgIconFacebook',
	instagramUrl: 'SvgIconInstagram',
	linkedInUrl: 'SvgIconLinkedIn',
	twitterUrl: 'SvgIconTwitter',
};

export default {
	name: 'SiteFooter',

	components: {
		SiteLogo,
		LensFlare,
		UmbracoImage,
		SvgLogo,
		SvgIconArrow,
		SvgIconPlay,
		SvgIconPause,
		SvgIconFacebook,
		SvgIconInstagram,
		SvgIconLinkedIn,
		SvgIconTwitter,
		SvgIconSpeaker,
	},

	data() {
		return {
			isSystemReducedMotion: true,
		};
	},

	computed: {
		siteUrl() {
			return this.$store.state.site?.url || '/';
		},
		...mapState({
			isReducedMotion: (state) => state.isReducedMotion,
			address: (state) => state.site?.footer?.address,
			seeAlso: (state) => state.site?.footer?.seeAlso,
			soMeItems: (state) =>
				Object.keys(state.site?.soMeItems || {})
					.map((type) => ({
						serviceName: type.split('Url')[0],
						url: state.site?.soMeItems[type],
						icon: iconMap[type],
					}))
					.filter((e) => Boolean(e.url)),
			showCityOfArms: (state) => state.site?.footer?.showCityOfArms,
			showPartners: (state) => state.site?.footer?.showPartners,
			siteName: (state) => state.site?.name,
			titleCityOfArms: (state) => state.site?.footer?.titleCityOfArms,
			partners: (state) =>
				state.site?.footer?.showPartners &&
				state.site?.footer?.partners,
		}),
	},

	mounted() {
		this.isSystemReducedMotion =
			window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
			window.matchMedia(`(prefers-reduced-motion: reduce)`).matches ===
				true;
	},

	methods: {
		isExternal(url) {
			if (url) {
				if (
					['http://', 'https://', 'ftp://'].some(
						(str) => url.indexOf(str) === 0
					)
				) {
					return true;
				}

				const splitOut = url.split('/');
				if (splitOut[0].indexOf('.') >= 0) {
					return true;
				}
			}

			return false;
		},

		partnerLinkUnwrap(partner) {
			return partner?.partnerLink?.[0] || {};
		},

		imageSizes,

		toggleAnimation() {
			if (this.isReducedMotion) {
				location.hash = '#no-reduced-motion';
				location.reload();
			} else {
				location.hash = '#reduced-motion';
				location.reload();
			}
		},
	},
};
</script>

<style lang="postcss">
/* Part of the page transitioning */
.c-site-footer {
	@apply transition duration-300 ease-in-out;
}

.c-site-footer .partners {
	background-color: #fafafa;
}
.c-site-footer .spacer {
	background-color: rgb(45, 45, 45);
	padding-top: 1.5px !important;
	opacity: 0.15;
}

.js-page-transition:not(.js-page-transition--enter) .c-site-footer {
	@apply transform translate-y-6 opacity-0;
}

.c-site-footer__link {
	word-break: break-word;
}

.c-site-footer__partner figure picture img {
	@apply object-contain !important;
	aspect-ratio: 1 / 1;
}

@screen <1024 {
	.c-site-footer .seealso,
	.c-site-footer .cityofarms {
		border-top: 1.5px solid rgba(45, 45, 45, 0.15) !important;
	}
}
</style>
