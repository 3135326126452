var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-site-footer"},[_c('div',{staticClass:"relative h-80 >=1024:h-180 overflow-hidden"},[_c('LensFlare',{class:[
				'absolute top-1/2 left-1/2 w-visual-screen max-w-layout-max',
				'transform -translate-x-1/2 -translate-y-1/2',
				'hidden >=1024:block',
			],attrs:{"id":"c-site-footer__flare-desktop","angle":4,"min-blur-amount":5,"max-blur-amount":30,"flares":[
				{
					size: 525,
					offset: 0,
					targetOffset: -150,
					theme: 'primary',
					opacity: 1,
				},
				{
					size: 355,
					targetSize: 30,
					offset: -200,
					targetOffset: -640,
					theme: 'secondary',
					opacity: 0.8,
				},
				{
					size: 235,
					targetSize: 180,
					offset: 230,
					targetOffset: 570,
					theme: 'secondary',
					opacity: 0.5,
				},
				{
					size: 135,
					targetSize: 0,
					offset: 230,
					targetOffset: 1230,
					theme: 'secondary',
					opacity: 0.5,
				},
			]}}),_vm._v(" "),_c('LensFlare',{class:[
				'absolute top-1/2 left-1/2 w-visual-screen ',
				'transform -translate-x-1/2 -translate-y-1/2',
				'>=1024:hidden',
			],attrs:{"id":"c-site-footer__flare-mobile","angle":15,"min-blur-amount":15,"max-blur-amount":25,"flares":[
				{
					size: 625,
					offset: -50,
					targetOffset: 50,
					theme: 'primary',
					opacity: 1,
				},
				{
					size: 375,
					targetSize: 150,
					offset: -300,
					targetOffset: -450,
					theme: 'secondary',
					opacity: 0.8,
				},
				{
					size: 285,
					targetSize: 150,
					offset: 250,
					targetOffset: 650,
					theme: 'secondary',
					opacity: 0.5,
				},
			]}})],1),_vm._v(" "),_c('footer',{class:[
			_vm.showPartners ? 'pb-64' : 'pb-95 >=1024:pb-120',
			'max-w-layout-max px-layout-margin mx-auto flex flex-col items-start >=1024:grid >=1024:grid-cols-12 >=1024:gap-x-layout-gutter >=1024:gap-y-lg pt-4xl',
		],attrs:{"id":"footer"}},[_c('div',{staticClass:"order-first row-start-1 col-start-1 col-end-13"},[_c('SiteLogo',{staticClass:"w-full h-auto",attrs:{"to":_vm.siteUrl,"max-width":360,"max-height":54}})],1),_vm._v(" "),(_vm.address)?_c('div',{staticClass:"order-1 row-start-2 col-start-1 col-end-5 mt-2xl >=1024:mt-0"},[(_vm.siteName !== null)?_c('BaseH3',{staticClass:"mb-32",attrs:{"tag":"h2"},domProps:{"textContent":_vm._s(_vm.siteName)}}):_vm._e(),_vm._v(" "),_c('div',[_vm._v("\n\t\t\t\t"+_vm._s(_vm.address.street)),_c('br'),_vm._v("\n\t\t\t\t"+_vm._s(_vm.address.postalCode)+" "+_vm._s(_vm.address.city)+"\n\t\t\t")]),_vm._v(" "),(_vm.address.phone || _vm.address.email)?_c('div',{staticClass:"mt-lg"},[(_vm.address.phone)?_c('a',{staticClass:"block",attrs:{"href":`tel:${_vm.address.phone}`}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.address.phone)+"\n\t\t\t\t")]):_vm._e(),_vm._v(" "),(_vm.address.email)?_c('a',{staticClass:"block",attrs:{"href":`mailto:${_vm.address.email}`}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.address.email)+"\n\t\t\t\t")]):_vm._e()]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.soMeItems)?_c('div',{staticClass:"order-4 row-start-4 col-start-1 col-end-10 mt-32 >=1024:mt-0 >=1024:self-center"},[_c('div',{staticClass:"flex space-x-12"},_vm._l((_vm.soMeItems),function({ serviceName, url, icon },index){return _c('a',{key:`some-${index}`,attrs:{"href":url,"aria-label":`gå til ${serviceName}`}},[_c(icon,{tag:"Component",staticClass:"text-text w-32"})],1)}),0)]):_vm._e(),_vm._v(" "),(_vm.seeAlso)?[(_vm.seeAlso.links && _vm.seeAlso.links.length)?_c('div',{staticClass:"seealso w-full order-2 row-start-2 col-start-5 col-end-9 mt-32 pt-32 >=1024:pt-0 >=1024:mt-0"},[(_vm.seeAlso.title)?_c('BaseH3',{staticClass:"mb-32",attrs:{"tag":"h2"},domProps:{"textContent":_vm._s(_vm.seeAlso.title)}}):_vm._e(),_vm._v(" "),(_vm.seeAlso.links && _vm.seeAlso.links.length)?_c('div',{class:[
						'>=1024:mt-0',
						{
							'mt-5xl': !_vm.seeAlso.title,
							'mt-xl': _vm.seeAlso.title,
						},
					]},[_c('div',{staticClass:"space-y-8"},_vm._l((_vm.seeAlso.links),function(link,index){return _c('NuxtLinkExt',{key:`see-also-link-${index}`,class:[
								'c-site-footer__link pr-8',
								'flex items-start space-x-16',
								'text-button font-medium font-darker-grotesque',
								'group',
							],attrs:{"to":link.url,"target":link.target}},[_c('SvgIconArrow',{class:[
									'flex-shrink-0',
									'mt-4 w-16 text-text',

									{
										'transform -rotate-45':
											link.url === '_blank' ||
											_vm.isExternal(link.url),
									},
								]}),_vm._v(" "),_c('div',{class:[
									'mb-4',
									'duration-500 ease-smooth-out',
									'transform group-hover:translate-x-8',
								],domProps:{"textContent":_vm._s(link.name)}})],1)}),1)]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.seeAlso.readOutLoud || !_vm.isSystemReducedMotion)?_c('div',{staticClass:"order-5 flex flex-col row-start-4 col-start-10 col-end-13 w-full mt-32 >=1024:mt-0 >=1024:self-center >=1024:items-end space-y-8"},[_c('NuxtLinkExt',{class:[
						'flex items-center space-x-12',
						'text-button font-medium font-darker-grotesque',
						'group pr-8',
					],attrs:{"to":_vm.seeAlso.readOutLoud.url,"target":_vm.seeAlso.readOutLoud.target}},[_c('SvgIconSpeaker',{staticClass:"flex-shrink-0 w-18"}),_vm._v(" "),_c('div',{class:[
							'mb-4',
							'duration-500 ease-smooth-out',
							'transform group-hover:translate-x-8',
						],domProps:{"textContent":_vm._s('Læs højt')}})],1),_vm._v(" "),(!_vm.isSystemReducedMotion)?_c('button',{class:[
						'flex items-center space-x-12',
						'text-button font-medium font-darker-grotesque',
						'group pr-8',
					],on:{"click":_vm.toggleAnimation}},[(_vm.isReducedMotion)?_c('SvgIconPlay',{staticClass:"flex-shrink-0 w-18"}):_c('SvgIconPause',{staticClass:"flex-shrink-0 w-18"}),_vm._v(" "),_c('div',{class:[
							'mb-4',
							'duration-500 ease-smooth-out',
							'transform group-hover:translate-x-8',
						],domProps:{"textContent":_vm._s(
							_vm.isReducedMotion
								? 'Slå animeret pynt til'
								: 'Slå animeret pynt fra'
						)}})],1):_vm._e()],1):_vm._e()]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"spacer row-start-3 col-start-1 col-end-13"}),_vm._v(" "),(_vm.showCityOfArms)?_c('div',{staticClass:"cityofarms w-full order-3 flex flex-col row-start-2 row-end-4 col-start-10 col-end-13 mt-3xl pt-32 >=1024:items-end >=1024:mt-0 >=1024:pt-0"},[_c('div',{staticClass:"inline-block"},[(_vm.showCityOfArms && _vm.titleCityOfArms !== null)?_c('BaseH3',{staticClass:"mb-32",attrs:{"tag":"h2"},domProps:{"textContent":_vm._s(_vm.titleCityOfArms)}}):_vm._e(),_vm._v(" "),_c('a',{staticClass:"inline-block w-auto h-72 >=1024:h-80",staticStyle:{"aspect-ratio":"147 / 57"},attrs:{"href":"https://odsherred.dk/","target":"_blank","aria-label":"til Odsherred Kommunes hjemmeside"}},[_c('SvgLogo',{staticClass:"w-auto h-full"})],1)],1)]):_vm._e()],2),_vm._v(" "),(_vm.showPartners)?_c('div',{staticClass:"partners w-screen pb-95 >=1024:pb-105"},[_c('div',{staticClass:"flex flex-col items-start max-w-layout-max px-layout-margin mx-auto pt-64 >=1024:items-center"},[(_vm.partners.title)?_c('BaseH3',{staticClass:"mb-32",attrs:{"tag":"h2"},domProps:{"textContent":_vm._s(_vm.partners.title)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex items-center flex-col w-full space-y-16 >=1024:w-auto >=1024:flex-row >=1024:space-x-16 >=1024:space-y-0"},_vm._l((_vm.partners.items),function({ content: partner }){return _c('a',{key:partner.key,staticClass:"c-site-footer__partner text-center w-full >=1024:w-145",attrs:{"href":_vm.partnerLinkUnwrap(partner).url,"target":_vm.partnerLinkUnwrap(partner).target}},[_c('figure',{staticClass:"flex space-x-16 items-center >=1024:space-x-0 >=1024:flex-col"},[(partner.partnerlogo)?_c('UmbracoImage',{staticClass:"w-48 h-48 object-contain aspect-square >=1024:w-80 >=1024:h-80",attrs:{"widths":[80],"sizes":_vm.imageSizes({
									default: '100vw',
								}),"alt":partner.partnerlogo.altText,"source-url":partner.partnerlogo.cropUrl,"source-width":partner.partnerlogo.width,"source-height":partner.partnerlogo.height,"lazyload":false}}):_vm._e(),_vm._v(" "),(partner.partnerName)?_c('figcaption',{staticClass:"font-darker-grotesque font-medium text-button leading-1 >=1024:mt-8",domProps:{"textContent":_vm._s(partner.partnerName)}}):_vm._e()],1)])}),0)],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }