var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"c-site-header",attrs:{"inert":_vm.menuIsOpen ? true : null}},[_c('div',{staticClass:"top-0 bg-white filter transition-all duration-500",class:[
			_vm.keepAtTop
				? 'absolute -left-layout-margin -right-layout-margin'
				: 'fixed left-0 w-full',
			{
				'h-site-header bg-opacity-0 drop-shadow-none':
					!_vm.isSticky && !_vm.onSearchPage,
				'h-site-header-compact drop-shadow-14 >=1024:drop-shadow-20':
					_vm.isSticky && !_vm.onSearchPage,
				'h-80 >=768:h-site-header bg-opacity-0': _vm.onSearchPage,
			},
		]},[_c('div',{staticClass:"w-full h-full",style:(_vm.contentStyle)},[(_vm.onSearchPage)?_c('SearchLongread',{staticClass:"absolute top-full"}):_vm._e(),_vm._v(" "),_c('div',{class:[
					'relative w-full h-full z-10',
					'transition-all duration-500',
					{ 'bg-transparent': !_vm.onSearchPage },
					{ 'bg-secondary': _vm.onSearchPage },
				]},[_c('div',{staticClass:"w-full h-full max-w-layout-max px-layout-margin mx-auto flex items-center justify-end gap-xl"},[_c('TransitionExt',{attrs:{"name":"fade-in-out","duration":"500"}},[(!_vm.onSearchPage)?_c('SiteLogo',{class:['flex-shrink-0 mr-auto'],attrs:{"to":_vm.url}}):_vm._e()],1),_vm._v(" "),_c('TransitionExt',{attrs:{"name":"fade-in-out","duration":"500"}},[(
								!_vm.onSearchPage &&
								_vm.navigation &&
								_vm.navigation.main &&
								_vm.navigation.main.length &&
								_vm.writeOutNavigation
							)?_c('nav',{staticClass:"w-0 flex justify-end items-center gap-2xl invisible >=1400:visible flex-grow",attrs:{"aria-label":"hovednavigation"}},_vm._l((_vm.navigation.main),function(item,index){return _c('NuxtLinkExt',{key:index,staticClass:"font-darker-grotesque font-medium text-button-sm whitespace-nowrap",attrs:{"to":item.url},domProps:{"innerHTML":_vm._s(item.title)}})}),1):_vm._e()]),_vm._v(" "),_c('TransitionExt',{attrs:{"name":"fade-in-out","duration":"500"}},[(
								!_vm.onSearchPage &&
								_vm.navigation &&
								((_vm.navigation.focusItem &&
									_vm.navigation.focusItem.url) ||
									(_vm.navigation.languages &&
										_vm.navigation.languages.length))
							)?_c('nav',{class:[
								'flex-shrink-0 flex items-center gap-sm',
								'duration-500',

								{
									'opacity-0': _vm.onSearchPage,
									'delay-500': !_vm.onSearchPage,
								},
							],attrs:{"aria-label":"hurtig bar","tabindex":_vm.onSearchPage ? -1 : null,"inert":_vm.onSearchPage ? true : null}},[(
									_vm.navigation.focusItem &&
									_vm.navigation.focusItem.url
								)?[_c('NuxtLinkExt',{staticClass:"font-darker-grotesque font-semibold text-button-xs uppercase",attrs:{"to":_vm.navigation.focusItem.url},domProps:{"textContent":_vm._s(_vm.navigation.focusItem.name)}}),_vm._v(" "),_c('span',{staticClass:"c-site-header__menu-separator"})]:_vm._e(),_vm._v(" "),(
									_vm.navigation.languages &&
									_vm.navigation.languages.length
								)?[_c('LanguagePicker',{attrs:{"items":_vm.navigation.languages}}),_vm._v(" "),_c('span',{staticClass:"c-site-header__menu-separator"})]:_vm._e()],2):_vm._e()]),_vm._v(" "),_c('div',{class:[
							'flex-shrink-0',
							'hidden >=768:inline-flex',
							'justify-end items-center gap-sm',
							'duration-500 w-176',

							{
								'w-full gap-5xl': _vm.onSearchPage,
								'delay-300': !_vm.onSearchPage,
							},
						]},[(_vm.searchPage)?_c('SiteHeaderSearch',{class:[
								'flex-shrink-0',
								'duration-300',

								{
									'h-60 flex-grow delay-500':
										_vm.onSearchPage,
								},
							],attrs:{"use-autosuggest":"","show-input":_vm.keepAtTop || _vm.onSearchPage,"button-mode":_vm.keepAtTop || _vm.onSearchPage
									? 'button'
									: 'link'},nativeOn:{"focusin":function($event){_vm.scrollPercentage = 0}}}):_vm._e(),_vm._v(" "),_c('div',{class:[
								'flex transition-all duration-500',
								{
									'>=1400:max-w-0 >=1400:invisible >=1400:opacity-0':
										_vm.writeOutNavigation && !_vm.onSearchPage,
								},
							],staticStyle:{"max-width":"10rem"}},[(_vm.onSearchPage || _vm.writeOutNavigation)?_c('NuxtLinkExt',{attrs:{"aria-label":"luk søgningen","to":_vm.previousPath}},[_c('BaseButton',{class:[
										'c-site-header__button',
										'flex-shrink-0',
										'whitespace-nowrap',
										'w-120',
										{
											'<1400:hidden':
												_vm.writeOutNavigation &&
												!_vm.onSearchPage,
										},
									],style:(_vm.buttonStyle),attrs:{"id":"desktop-close-search","tag":"div","slim":"","dark":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',[_vm._v("Luk")])]},proxy:true},{key:"icon",fn:function(){return [_c('SvgIconClose')]},proxy:true}],null,false,146148074)})],1):_vm._e(),_vm._v(" "),(_vm.navigation && !_vm.onSearchPage)?_c('BaseButton',{class:[
									'c-site-header__button',
									'flex-shrink-0',
									'whitespace-nowrap',
									'w-120',
									{
										'>=1400:hidden': _vm.writeOutNavigation,
									},
								],style:(_vm.buttonStyle),attrs:{"id":"desktop-menu","slim":""},on:{"click":() => _vm.toggleMenu(true)},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',[_vm._v("Menu")])]},proxy:true},{key:"icon",fn:function(){return [_c('SvgIconMenu')]},proxy:true}],null,false,1133735278)}):_vm._e()],1)],1)],1)])],1)]),_vm._v(" "),_c('div',{staticClass:"c-site-header__bottom-bar >=768:hidden isolate",class:[
			'fixed bottom-0 left-0 w-full px-layout-margin pb-md',
			'flex justify-end items-center gap-sm',
			{
				'c-site-header__bottom-bar--has-gradient':
					(!_vm.isSticky || _vm.searchHasFocus || _vm.searchHasInput) &&
					_vm.isFrontpage,
			},
		]},[_c('Transition',{attrs:{"name":"t-site-header__mobile-search","mode":"out-in"}},[(_vm.searchPage)?_c('SiteHeaderSearch',{key:`state-${_vm.onSearchPage}`,ref:"mobileSearch",staticClass:"flex-shrink flex-grow-0",class:{
					'w-full':
						(!_vm.isSticky || _vm.searchHasFocus || _vm.searchHasInput) &&
						_vm.isFrontpage,
					'duration-500 ease-smooth-out': !_vm.onSearchPage,
					'fixed top-md left-layout-margin w-5/5col direction-reverse':
						_vm.onSearchPage,
				},style:(_vm.onSearchPage ? _vm.contentStyle : ''),attrs:{"show-input":((!_vm.isSticky || _vm.searchHasFocus || _vm.searchHasInput) &&
						_vm.isFrontpage) ||
					_vm.onSearchPage,"button-mode":_vm.onSearchPage ? 'button' : 'link',"white-background":""},nativeOn:{"focusin":function($event){return _vm.onSearchFocus.apply(null, arguments)},"focusout":function($event){return _vm.onSearchBlur.apply(null, arguments)}}}):_vm._e()],1),_vm._v(" "),(_vm.navigation && !_vm.onSearchPage)?_c('BaseButton',{staticClass:"w-48 h-48 flex-shrink-0",attrs:{"id":"mobile-menu","aria-label":"Åben menu"},on:{"click":() => _vm.toggleMenu(true)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgIconMenu')]},proxy:true}],null,false,2095407193)}):(_vm.onSearchPage)?_c('NuxtLinkExt',{attrs:{"to":_vm.previousPath}},[_c('BaseButton',{staticClass:"w-48 h-48 flex-shrink-0",attrs:{"id":"mobile-menu","dark":"","aria-label":"Luk søgning"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgIconClose')]},proxy:true}])})],1):_vm._e()],1),_vm._v(" "),_c('div',{class:[
			'fixed top-0 left-0 w-full h-full pointer-events-none',
			'bg-black bg-opacity-75 opacity-0',
			'duration-1000 ease-smooth-out',

			{
				'opacity-100': _vm.menuIsOpen,
			},
		]}),_vm._v(" "),_c('Portal',{attrs:{"name":"BurgerMenu","to":"overlay"}},[_c('Transition',{attrs:{"name":"t-site-header__burger-menu","duration":"1000","appear":""}},[(_vm.menuIsOpen)?_c('BurgerMenu',{key:"BurgerMenu",on:{"close":() => _vm.toggleMenu(false)}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }