<template>
	<ScopedColorTheme
		class="l-subsite w-full min-h-full"
		:theme="colorTheme || 'blue'"
	>
		<div class="max-w-layout-max px-layout-margin mx-auto">
			<SkipToContentCustom
				:content="{
					main: 'Hovedindhold',
					footer: 'Sidefod',
				}"
			/>

			<!--
				Remove if cookieinformation is not picked as
				the cookie banner solution.
			-->
			<CookieInformation :tag="false" />

			<!--
				Go to ~/assets/js/theme-configuration.default.js to set
				the default solution configuration (typically
				the configuration of the main solution).
			-->
			<ThemeConfiguration />

			<!--
				Functionality used only during development,
				fx. show grid on page.
			-->
			<DeveloperTools v-if="environment === 'development'" />

			<!--
				Content
			-->
			<SiteHeader v-if="showHeader" ref="siteHeader" />

			<BreakingBanner
				v-if="breakingNews"
				v-bind="breakingNews"
				class="my-14 >=1024:my-lg"
			/>

			<main id="main" style="min-height: min(40vw, 60vh)">
				<Nuxt />
			</main>
		</div>

		<Transition name="t-subsite__footer" :duration="500">
			<SiteFooter
				v-if="showFooter && template !== 'SearchPageSubSite'"
				class="mt-8xl"
			/>
		</Transition>

		<!--
			Portal Targets
		-->
		<GlobalOverlay />
	</ScopedColorTheme>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import ThemeConfiguration from '~/citi-baseline/components/ThemeConfiguration';
import CookieInformation from '~/citi-baseline/components/CookieInformation';
import SkipToContentCustom from '~/components/shared/SkipToContentCustom';
import DeveloperTools from '~/components/shared/DeveloperTools';
import SiteHeader from '~/components/subsite/SiteHeader';
import BreakingBanner from '~/components/shared/BreakingBanner';
import SiteFooter from '~/components/subsite/SiteFooter';
import GlobalOverlay from '~/components/shared/GlobalOverlay';
import ScopedColorTheme from '~/components/shared/ScopedColorTheme';

export default {
	name: 'SubsiteDefault',

	components: {
		SkipToContentCustom,
		ThemeConfiguration,
		CookieInformation,
		DeveloperTools,
		SiteHeader,
		BreakingBanner,
		SiteFooter,
		GlobalOverlay,
		ScopedColorTheme,
	},

	data() {
		return {
			environment: process?.env?.NODE_ENV,
		};
	},

	computed: {
		breakingNews() {
			return this.$store.state.site?.breakingNews;
		},
		showHeader() {
			return true;
		},
		showFooter() {
			// todo: Disable on search page
			return this.$store.state.site?.footer;
		},
		...mapGetters(['colorTheme']),
		...mapState(['template']),
	},
};
</script>

<style lang="postcss">
.t-subsite__footer-enter-active,
.t-subsite__footer-leave-active {
	@apply duration-300 ease-smooth-out;
}
.t-subsite__footer-enter,
.t-subsite__footer-leave-to {
	@apply opacity-0;
}
</style>
