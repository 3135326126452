<template>
	<div class="c-burger-menu relative bg-white h-full overflow-hidden">
		<!-- Lens Flare -->
		<Transition name="t-burger-menu__flare" :duration="1600" appear="">
			<LensFlare
				id="c-burger-menu__flare"
				:class="[
					'c-burger-menu__lens-flare',
					'absolute bottom-0 right-0',
					'<1024:top-0 <1024:bottom-auto',
					'w-1/2',
				]"
				:angle="flareAngle"
				:min-blur-amount="15"
				:max-blur-amount="15"
				:flares="[
					{
						size: 1000,
						offset: 300,
						opacity: 1,
						theme: 'primary',
						classes: [
							'c-burger-menu__flare-first',
							'duration-1000 ease-smooth-out',
						],
					},
					{
						size: 500,
						offset: -200,
						opacity: 0.8,
						theme: 'secondary',
						classes: [
							'c-burger-menu__flare-second',
							'duration-1000 ease-smooth-out',
						],
					},
				]"
			/>
		</Transition>

		<div
			:class="[
				'relative max-w-layout-max',
				'mx-auto h-full overflow-y-scroll',
				'px-layout-margin pt-xl pb-md >=1024:pt-lg >=1024:pb-4xl',
				'flex flex-col justify-between',
			]"
		>
			<!-- Top -->
			<div class="relative hidden >=1024:block">
				<BaseButton class="absolute right-0" dark @click="closeOverlay">
					<template #default>
						<span v-text="'LUK'"></span>
					</template>

					<template #icon>
						<SvgClose class="w-14" />
					</template>
				</BaseButton>
			</div>

			<!-- Content -->
			<div
				:class="[
					'relative h-full',
					'flex >=1024:grid >=1024:grid-cols-12',
					'gap-x-layout-gutter >=1024:mt-3xl',
					'duration-500 ease-smooth-out',
				]"
			>
				<div
					ref="parentItems"
					:class="[
						'w-5/5col h-full',
						'>=1024:block >=1024:relative >=1024:w-auto',
						'>=1024:col-start-1 >=1024:col-end-6',
					]"
				>
					<div class="h-6xl" style="max-height: 10vh"></div>
					<ul class="space-y-lg">
						<li
							v-for="(item, index) in main"
							:key="`main-item-${index}`"
						>
							<NuxtLinkExt
								:class="[
									'flex items-center',
									'text-menu-category font-darker-grotesque',
									'duration-500 ease-smooth-out',
									'transform group text-left',

									{
										'opacity-60 hover:opacity-100':
											activeItem &&
											activeItem.id !== item.id,
									},
								]"
								:to="item.url"
							>
								<BurgerMenuArrow
									class="flex-shrink-0"
									:active="
										activeItem && activeItem.id === item.id
									"
								/>
								<span
									:class="[
										'mb-6 transform',
										'transition-transform duration-500 ease-smooth-out',

										{
											'translate-x-20':
												activeItem &&
												activeItem.id === item.id,
											'group-hover:translate-x-20':
												!activeItem ||
												activeItem.id !== item.id,
										},
									]"
									v-html="item.title"
								></span>
							</NuxtLinkExt>
						</li>
					</ul>
					<div class="h-6xl" style="max-height: 10vh"></div>
				</div>
			</div>

			<!-- Bottom -->
			<div
				:class="[
					'c-burger-menu__bottom',
					'grid grid-cols-1 gap-sm max-w-fit mt-2xl',
					'>=1024:flex >=1024:flex-wrap >=1024:w-5/12col',
				]"
			>
				<NuxtLinkExt
					v-for="(item, index) in computedSecondary"
					:key="`secondary-${index}`"
					:to="item.url"
					:target="item.target"
				>
					<BaseButton
						:dark="!item.isExternal"
						:light="item.isExternal"
						class="<1024:h-48 <1024:min-w-full"
					>
						<template #default>
							<div
								class="mb-1 >=1024:mb-0"
								v-text="item.title || item.name"
							></div>
						</template>
						<template #icon>
							<SvgArrow
								v-if="item.isExternal"
								class="transform -rotate-45"
							/>
						</template>
					</BaseButton>
				</NuxtLinkExt>

				<BaseButton
					dark
					:class="[
						'c-burger-menu__close-mobile >=1024:hidden',
						'fixed bottom-md right-layout-margin',
					]"
					aria-label="Luk menu"
					@click="closeOverlay"
				>
					<template #icon>
						<SvgClose class="w-14" />
					</template>
				</BaseButton>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import LensFlare from '~/components/shared/LensFlare';
import BurgerMenuArrow from '~/components/shared/BurgerMenuArrow';
import SvgArrow from '~/assets/svgs/icon-arrow.svg?inline';
import SvgClose from '~/assets/svgs/icon-close.svg?inline';

export default {
	name: 'BurgerMenu',

	components: {
		LensFlare,
		BurgerMenuArrow,
		SvgArrow,
		SvgClose,
	},

	data() {
		const { navigation } = this.$store.state.site || {};

		return {
			flareAngle: 20,
			flareAngles: navigation.main?.map(
				(_, n) => Math.sin(n * 0.5) * 20 + 10
			),

			activeItem: null,
			main: null,
			secondary: null,
			...navigation,
		};
	},

	computed: {
		...mapState(['pageId']),

		computedSecondary() {
			return this.secondary
				.map((e) => ({
					...e,
					isExternal: this.isExternal(e.url),
				}))
				.sort(({ isExternal: a }, { isExternal: b }) => {
					return a === b ? 0 : a && !b ? 1 : -1;
				});
		},
	},

	watch: {
		'$route.fullPath'() {
			this.closeOverlay();
		},
	},

	mounted() {
		const activeItem = this.main.find((parent) => {
			const onParent = parent.id === this.pageId;
			const onChild =
				this.$route.path.slice(0, parent.url.length) === parent.url;
			return onParent || onChild;
		});

		this.activeItem = activeItem;
	},

	methods: {
		closeOverlay() {
			this.$emit('close');
		},

		/** Helper functions */
		isExternal(url) {
			if (url) {
				if (
					['http://', 'https://', 'ftp://'].some(
						(str) => url.indexOf(str) === 0
					)
				) {
					return true;
				}

				const splitOut = url.split('/');
				if (splitOut[0].indexOf('.') >= 0) {
					return true;
				}
			}

			return false;
		},
	},
};
</script>

<style lang="postcss">
@screen <1024 {
	.c-burger-menu__bottom .c-base-button__inner {
		@apply justify-center px-2xl;
	}

	.c-burger-menu__close-mobile .c-base-button__inner {
		@apply w-48 h-48 !important;
	}
}

.c-burger-menu__lens-flare {
	transform: translateY(-64%) rotate(55deg) scaleX(-1);
}

@screen >=1024 {
	.c-burger-menu__lens-flare {
		transform: translateY(70%) !important;
	}
}

.t-burger-menu-parent-enter-active,
.t-burger-menu-parent-leave-active,
.t-burger-menu-children-enter-active,
.t-burger-menu-children-leave-active {
	@apply transform ease-smooth-out;
}

.t-burger-menu-parent-enter,
.t-burger-menu-parent-leave-to {
	@apply -translate-x-full opacity-0;
}

.t-burger-menu-children-enter,
.t-burger-menu-children-leave-to {
	@apply translate-x-full opacity-0;
}

@screen >=1024 {
	.t-burger-menu-parent-enter,
	.t-burger-menu-parent-leave-to {
		@apply translate-x-0 opacity-100;
	}

	.t-burger-menu-children-enter {
		@apply -translate-x-52 opacity-0;
	}

	.t-burger-menu-children-leave-to {
		@apply translate-x-52 opacity-0;
	}
}

#c-burger-menu__flare {
	z-index: initial;
}

@screen <1024 {
	#c-burger-menu__flare {
		right: -10% !important;
	}
}

.c-burger-menu__flare-first {
	animation: burgerMenuFlare 5000ms ease-in-out infinite;
}

.c-burger-menu__flare-second {
	animation: burgerMenuFlare 5000ms ease-in-out infinite;
	animation-delay: 1000ms;
}

@media (prefers-reduced-motion) {
	.c-burger-menu__flare-first,
	.c-burger-menu__flare-second {
		animation: unset;
	}
}
.reduced-motion {
	.c-burger-menu__flare-first,
	.c-burger-menu__flare-second {
		animation: unset;
	}
}

@keyframes burgerMenuFlare {
	0%,
	100% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(-48px);
	}
}

/**
	Lens flare transition
 */
@screen >=1024 {
	.t-burger-menu__flare-enter-active,
	.t-burger-menu__flare-leave-active {
		@apply duration-1000 ease-smooth-out;
		transition-delay: 600ms;
	}
	.t-burger-menu__flare-enter,
	.t-burger-menu__flare-leave-to {
		@apply opacity-0;
		transform: translateY(90%) !important;
	}
}
</style>
