<template>
	<Transition name="t-search-longread" :duration="600">
		<div
			v-if="groups.length"
			:class="[
				'c-search-longread',
				'w-full max-h-60 >=768:max-h-80 h-80',
				'bg-secondary shadow-30',
			]"
		>
			<div
				:class="[
					'c-search-longread__inner',
					'max-w-layout-max mx-auto px-layout-margin',
					'w-full h-full flex items-center',
				]"
			>
				<!-- Items -->
				<Transition
					name="t-search-section-long-read__items"
					mode="out-in"
				>
					<div
						ref="scroller"
						:key="key"
						:class="[
							'relative h-full',
							'pointer-events-auto <768:overflow-x-scroll',
							'<768:-mx-layout-margin mb-3',
						]"
						@scroll="onInnerScroll"
					>
						<div
							:key="key"
							:style="indicatorStyle"
							:class="[
								'c-search-section-long-read__indicator',
								'rounded-full bg-text h-32 z-0',
								'absolute top-1/2 left-0',
								'duration-200 ease-silk-out',
							]"
						></div>

						<div
							:class="[
								'c-search-section-long-read__items',
								'h-full >=768:max-w-10/12col',
								'min-w-fit whitespace-nowrap <768:px-layout-margin',
								'flex flex-nowrap gap-x-12 >=768:gap-x-20 items-center',
							]"
						>
							<a
								v-for="(group, index) in groups"
								:key="`longread-${index}`"
								ref="buttons"
								:href="`#${group.targetId}`"
								:class="[
									'text-label-card z-10 px-xs',
									'font-darker-grotesque font-semibold',
									'white-space-nowrap min-w-fit transition-colors duration-200 ease-silk-out',
									{
										'text-white': index === activeGroup,
									},
								]"
								:aria-current="
									activeGroup === index ? 'true' : null
								"
								@click.prevent="() => onClick(group.targetId)"
							>
								<span v-text="group.name"></span>

								<span
									:class="[
										'ml-4',
										{ 'opacity-70': index !== activeGroup },
									]"
									v-text="group.total"
								></span>
							</a>
						</div>
					</div>
				</Transition>
			</div>

			<Transition name="t-search-section-long-read__gradient">
				<div
					v-if="canScrollLeft"
					:class="[
						'c-search-section-long-read__gradient-left',
						'flex justify-start items-center pl-layout-margin',
						'pointer-events-auto >=768:hidden',
					]"
				>
					<BaseButton
						light
						class="w-24 h-24"
						aria-label="scroll tilbage"
						@click="scrollToPrevious"
					>
						<template #icon>
							<SvgCaret
								:class="[
									'w-10',
									'transform rotate-90 -translate-x-2',
								]"
							/>
						</template>
					</BaseButton>
				</div>
			</Transition>

			<Transition name="t-search-section-long-read__gradient">
				<div
					v-if="canScrollRight"
					:class="[
						'c-search-section-long-read__gradient-right',
						'flex justify-start items-center pl-layout-margin',
						'pointer-events-auto >=768:hidden',
					]"
				>
					<BaseButton
						light
						class="w-24 h-24 z-50"
						aria-label="scroll frem"
						@click="scrollToNext"
					>
						<template #icon>
							<SvgCaret
								:class="[
									'w-10',
									'transform rotate-90 -translate-x-2',
								]"
							/>
						</template>
					</BaseButton>
				</div>
			</Transition>
		</div>
	</Transition>
</template>

<script>
import { mapGetters } from 'vuex';

import SvgCaret from '~/assets/svgs/icon-caret.svg?inline';

export default {
	name: 'SearchLongread',
	components: { SvgCaret },

	data() {
		return {
			activeGroup: 0,
			indicatorWidth: 0,
			indicatorOffset: 0,
			activeOffset: 120,

			canScrollLeft: false,
			canScrollRight: false,
		};
	},

	computed: {
		...mapGetters({
			groups: 'MainSearchPage/longreadGroups',
		}),

		indicatorStyle() {
			const t = `transform: translate3d(${this.indicatorOffset}px, calc(-50% + 2px), 0)`;
			const w = `width: ${this.indicatorWidth}px`;
			return `${t}; ${w}`;
		},

		key() {
			const key = this.groups
				.filter((group) => group.total)
				.map((group) => group.total);

			return `longread-${key}`;
		},
	},

	watch: {
		groups: {
			immediate: true,
			handler() {
				this.$nextTick(() => {
					this.updateIndicator();
				});
			},
		},

		key() {
			this.$nextTick(() => {
				setTimeout(() => {
					this.onResize();
				}, 600);
			});
		},
	},

	mounted() {
		window.addEventListener('scroll', this.onScroll);
		window.addEventListener('resize', this.onResize);
		this.onInnerScroll();
		this.onResize();
	},

	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll);
		window.removeEventListener('resize', this.onResize);
	},

	methods: {
		onScroll() {
			/**
			 * Handles the movement of the indicator.
			 * Loops through the groups, gets their visibility,
			 * and sets the most visible one to be active.
			 */
			const targets = this.groups.map((group) => {
				if ((group = document.getElementById(group.targetId))) {
					const { top, height } = group.getBoundingClientRect();
					const bottom = -(window.innerHeight - (top + height));

					const ot = Math.max(0, -top + this.activeOffset);
					const ob = Math.max(0, bottom);
					const p = (height - (ot + ob)) / height;
					return Math.min(Math.max(p, 0), 1);
				}

				return 0;
			});

			const { index } = targets.reduce(
				(acc, cur, index) => ({
					index: cur > acc.value ? index : acc.index,
					value: cur > acc.value ? cur : acc.value,
				}),
				{
					index: 0,
					value: 0,
				}
			);

			if (this.activeGroup !== index) {
				this.updateIndicator(index);
			}
		},

		onClick(id) {
			if (document.getElementById(id)) {
				const target = document.getElementById(id);
				const direction = target.offsetTop < window.scrollY;

				/** Magic numbers, unfortunately... :( */
				const desktop = direction ? 240 : 140;
				const mobile = direction ? 135 : 60;
				const offset = window.innerWidth < 768 ? mobile : desktop;

				window.scrollTo({
					top: target.offsetTop - offset,
					// behavior: 'smooth',
				});
			}
		},

		onInnerScroll() {
			const { scroller } = this.$refs;
			const inner = scroller?.querySelector(
				'.c-search-section-long-read__items'
			);

			if (scroller && inner) {
				const { width: innerWidth } = inner.getBoundingClientRect();
				const { width: outerWidth } = scroller.getBoundingClientRect();
				const { scrollLeft } = scroller;

				this.canScrollLeft = scrollLeft > 4;
				this.canScrollRight = scrollLeft < innerWidth - outerWidth - 4;
			}
		},

		onResize() {
			const { innerWidth } = window;
			this.height = innerWidth >= 768 ? 95 : 72;
			this.updateIndicator(this.activeGroup);
			this.onInnerScroll();
		},

		updateIndicator(index = this.activeGroup) {
			if (this.$refs?.buttons?.[index]) {
				this.activeGroup = index;
				const button = this.$refs.buttons[index];
				const { width } = button.getBoundingClientRect();

				this.indicatorWidth = width;
				this.indicatorOffset = button.offsetLeft;

				if (this.$refs?.scroller) {
					const { scroller } = this.$refs;
					const buttonCenter = button.offsetLeft + width / 2;
					const screenCenter = window.innerWidth / 2;

					scroller.scrollTo({
						left: buttonCenter - screenCenter,
						behavior: 'smooth',
					});
				}
			}
		},
		scrollToNext() {
			const nearest = this.getNearestButton(-1);
			if (this.$refs?.buttons?.[nearest] && this.$refs?.scroller) {
				const button = this.$refs?.buttons?.[nearest];
				const { width } = button.getBoundingClientRect();
				const { scroller } = this.$refs;

				scroller.scrollTo({
					left: button.offsetLeft - window.innerWidth + width + 64,
					behavior: 'smooth',
				});
			}
		},

		scrollToPrevious() {
			const nearest = this.getNearestButton(1);
			if (this.$refs?.buttons?.[nearest] && this.$refs?.scroller) {
				const button = this.$refs?.buttons?.[nearest];
				const { scroller } = this.$refs;

				scroller.scrollTo({
					left: button.offsetLeft - 64,
					behavior: 'smooth',
				});
			}
		},

		getNearestButton(direction = 1) {
			if (this.$refs?.buttons) {
				let targets = this.$refs.buttons.map((button) => {
					const { left, width } = button.getBoundingClientRect();
					const right = -(window.innerWidth - (left + width));

					const ot = Math.max(0, -left + 32);
					const ob = Math.max(0, right + 32);
					const p = (width - (ot + ob)) / width;
					return Math.min(Math.max(p, 0), 1);
				});

				direction === -1 && (targets = targets.reverse());
				for (let n = 0; n < targets.length; n++) {
					if (targets[n] >= 0.99) {
						return Math.abs(
							(direction === -1 ? targets.length - 1 : 0) -
								(n - 1)
						);
					}
				}
			}
		},
	},
};
</script>

<style lang="postcss">
/**
	Main Transition
 */
.t-search-longread-enter-active,
.t-search-longread-enter-active .c-search-longread__inner {
	@apply duration-500 ease-smooth-out transform;
}
.t-search-longread-leave-active,
.t-search-longread-leave-active .c-search-longread__inner {
	@apply duration-500 ease-smooth-in transform;
}
.t-search-longread-enter,
.t-search-longread-leave-to {
	@apply opacity-0 -translate-y-32 !important;
}
.t-search-longread-enter .c-search-longread__inner,
.t-search-longread-leave-to .c-search-longread__inner {
	@apply opacity-0;
}

@screen >=1024 {
	.t-search-longread-enter,
	.t-search-longread-leave-to {
		@apply max-h-0 opacity-0 !important;
	}
	.t-search-longread-enter .c-search-longread__inner,
	.t-search-longread-leave-to .c-search-longread__inner {
		@apply opacity-0;
		transform: translate3d(0, -32px, 0);
	}
}

/**
	Items Transition
 */
.t-search-section-long-read__items-enter-active {
	@apply duration-300 transform ease-smooth-out delay-200;
}
.t-search-section-long-read__items-leave-active {
	@apply duration-300 transform ease-smooth-in;
}
.t-search-section-long-read__items-enter {
	@apply -translate-y-24 opacity-0;
}
.t-search-section-long-read__items-leave-to {
	@apply translate-y-12 opacity-0;
}

/**
	Gradients
 */
.c-search-section-long-read__gradient-left,
.c-search-section-long-read__gradient-right {
	background-color: #fff;
	background: linear-gradient(
		90deg,
		#ffffff 40%,
		rgba(255, 255, 255, 0) 100%
	);
	@apply absolute top-0 w-95 h-full z-20;
}
.c-search-section-long-read__gradient-left .c-base-button__inner,
.c-search-section-long-read__gradient-right .c-base-button__inner {
	border-width: 1.5px;
}
.c-search-section-long-read__gradient-left svg *,
.c-search-section-long-read__gradient-right svg * {
	stroke-width: 5px;
}
.c-search-section-long-read__gradient-left {
	@apply left-0 transform;
}
.c-search-section-long-read__gradient-right {
	@apply right-0 transform rotate-180;
}

/**
	Gradient Transiiton
 */
.t-search-section-long-read__gradient-enter-active,
.t-search-section-long-read__gradient-leave-active {
	@apply ease-silk-out duration-300;
}
.t-search-section-long-read__gradient-enter-active .c-base-button,
.t-search-section-long-read__gradient-leave-active .c-base-button {
	@apply ease-silk-out duration-300 transform;
}
.t-search-section-long-read__gradient-enter,
.t-search-section-long-read__gradient-leave-to {
	@apply -translate-x-full opacity-0;
}
.t-search-section-long-read__gradient-enter .c-base-button,
.t-search-section-long-read__gradient-leave-to .c-base-button {
	@apply -translate-x-16 opacity-0;
}
.t-search-section-long-read__gradient-enter.c-search-section-long-read__gradient-right,
.t-search-section-long-read__gradient-leave-to.c-search-section-long-read__gradient-right {
	@apply translate-x-full opacity-0;
}
.t-search-section-long-read__gradient-enter.c-search-section-long-read__gradient-right
	.c-base-button,
.t-search-section-long-read__gradient-leave-to.c-search-section-long-read__gradient-right
	.c-base-button {
	@apply translate-x-16 opacity-0;
}
</style>
