<template>
	<header class="c-site-header" :inert="menuIsOpen ? true : null">
		<!-- Top bar -->
		<div
			class="top-0 bg-white filter transition-all duration-500"
			:class="[
				keepAtTop
					? 'absolute -left-layout-margin -right-layout-margin'
					: 'fixed left-0 w-full',
				{
					'h-site-header bg-opacity-0 drop-shadow-none':
						!isSticky && !onSearchPage,
					'h-site-header-compact drop-shadow-14 >=1024:drop-shadow-20':
						isSticky && !onSearchPage,
					'h-80 >=768:h-site-header bg-opacity-0': onSearchPage,
				},
			]"
		>
			<div class="w-full h-full" :style="contentStyle">
				<!-- Search Longread -->
				<SearchLongread v-if="onSearchPage" class="absolute top-full" />

				<!-- Topbar content -->
				<div
					:class="[
						'relative w-full h-full z-10',
						'transition-all duration-500',
						{ 'bg-transparent': !onSearchPage },
						{ 'bg-secondary': onSearchPage },
					]"
				>
					<div
						class="
							w-full
							h-full
							max-w-layout-max
							px-layout-margin
							mx-auto
							flex
							items-center
							justify-end
							gap-xl
						"
					>
						<!-- Logo -->
						<TransitionExt name="fade-in-out" duration="500">
							<SiteLogo
								v-if="!onSearchPage"
								:class="['flex-shrink-0 mr-auto']"
								:to="url"
							/>
						</TransitionExt>

						<!-- Written navigation -->
						<TransitionExt name="fade-in-out" duration="500">
							<nav
								v-if="
									!onSearchPage &&
									navigation &&
									navigation.main &&
									navigation.main.length &&
									writeOutNavigation
								"
								class="
									w-0
									flex
									justify-end
									items-center
									gap-2xl
									invisible
									>=1400:visible
									flex-grow
								"
								aria-label="hovednavigation"
							>
								<NuxtLinkExt
									v-for="(item, index) in navigation.main"
									:key="index"
									:to="item.url"
									class="
										font-darker-grotesque font-medium
										text-button-sm
										whitespace-nowrap
									"
									v-html="item.title"
								>
								</NuxtLinkExt>
							</nav>
						</TransitionExt>

						<!-- Navigational actions -->
						<TransitionExt name="fade-in-out" duration="500">
							<nav
								v-if="
									!onSearchPage &&
									navigation &&
									((navigation.focusItem &&
										navigation.focusItem.url) ||
										(navigation.languages &&
											navigation.languages.length))
								"
								:class="[
									'flex-shrink-0 flex items-center gap-sm',
									'duration-500',

									{
										'opacity-0': onSearchPage,
										'delay-500': !onSearchPage,
									},
								]"
								aria-label="hurtig bar"
								:tabindex="onSearchPage ? -1 : null"
								:inert="onSearchPage ? true : null"
							>
								<!-- Focus item -->
								<template
									v-if="
										navigation.focusItem &&
										navigation.focusItem.url
									"
								>
									<NuxtLinkExt
										:to="navigation.focusItem.url"
										class="
											font-darker-grotesque font-semibold
											text-button-xs
											uppercase
										"
										v-text="navigation.focusItem.name"
									/>
									<span
										class="c-site-header__menu-separator"
									></span>
								</template>

								<!-- Languages -->
								<template
									v-if="
										navigation.languages &&
										navigation.languages.length
									"
								>
									<LanguagePicker
										:items="navigation.languages"
									/>
									<span
										class="c-site-header__menu-separator"
									></span>
								</template>
							</nav>
						</TransitionExt>

						<!-- Desktop -->
						<div
							:class="[
								'flex-shrink-0',
								'hidden >=768:inline-flex',
								'justify-end items-center gap-sm',
								'duration-500 w-176',

								{
									'w-full gap-5xl': onSearchPage,
									'delay-300': !onSearchPage,
								},
							]"
						>
							<!-- Search -->
							<SiteHeaderSearch
								v-if="searchPage"
								:class="[
									'flex-shrink-0',
									'duration-300',

									{
										'h-60 flex-grow delay-500':
											onSearchPage,
									},
								]"
								use-autosuggest
								:show-input="keepAtTop || onSearchPage"
								:button-mode="
									keepAtTop || onSearchPage
										? 'button'
										: 'link'
								"
								@focusin.native="scrollPercentage = 0"
							/>

							<!-- Menu -->
							<div
								:class="[
									'flex transition-all duration-500',
									{
										'>=1400:max-w-0 >=1400:invisible >=1400:opacity-0':
											writeOutNavigation && !onSearchPage,
									},
								]"
								style="max-width: 10rem"
							>
								<!-- Close search -->
								<NuxtLinkExt
									v-if="onSearchPage || writeOutNavigation"
									aria-label="luk søgningen"
									:to="previousPath"
								>
									<BaseButton
										id="desktop-close-search"
										tag="div"
										:style="buttonStyle"
										slim
										dark
										:class="[
											'c-site-header__button',
											'flex-shrink-0',
											'whitespace-nowrap',
											'w-120',
											{
												'<1400:hidden':
													writeOutNavigation &&
													!onSearchPage,
											},
										]"
									>
										<template #default>
											<span>Luk</span>
										</template>
										<template #icon>
											<SvgIconClose />
										</template>
									</BaseButton>
								</NuxtLinkExt>

								<!-- Menu -->
								<BaseButton
									v-if="navigation && !onSearchPage"
									id="desktop-menu"
									:style="buttonStyle"
									slim
									:class="[
										'c-site-header__button',
										'flex-shrink-0',
										'whitespace-nowrap',
										'w-120',
										{
											'>=1400:hidden': writeOutNavigation,
										},
									]"
									@click="() => toggleMenu(true)"
								>
									<template #default>
										<span>Menu</span>
									</template>
									<template #icon>
										<SvgIconMenu />
									</template>
								</BaseButton>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Bottom bar -->
		<div
			class="c-site-header__bottom-bar >=768:hidden isolate"
			:class="[
				'fixed bottom-0 left-0 w-full px-layout-margin pb-md',
				'flex justify-end items-center gap-sm',
				{
					'c-site-header__bottom-bar--has-gradient':
						(!isSticky || searchHasFocus || searchHasInput) &&
						isFrontpage,
				},
			]"
		>
			<!-- Search -->
			<Transition name="t-site-header__mobile-search" mode="out-in">
				<SiteHeaderSearch
					v-if="searchPage"
					ref="mobileSearch"
					:key="`state-${onSearchPage}`"
					:show-input="
						((!isSticky || searchHasFocus || searchHasInput) &&
							isFrontpage) ||
						onSearchPage
					"
					:button-mode="onSearchPage ? 'button' : 'link'"
					class="flex-shrink flex-grow-0"
					:style="onSearchPage ? contentStyle : ''"
					:class="{
						'w-full':
							(!isSticky || searchHasFocus || searchHasInput) &&
							isFrontpage,
						'duration-500 ease-smooth-out': !onSearchPage,
						'fixed top-md left-layout-margin w-5/5col direction-reverse':
							onSearchPage,
					}"
					white-background
					@focusin.native="onSearchFocus"
					@focusout.native="onSearchBlur"
				/>
			</Transition>

			<!-- Menu -->
			<BaseButton
				v-if="navigation && !onSearchPage"
				id="mobile-menu"
				class="w-48 h-48 flex-shrink-0"
				aria-label="Åben menu"
				@click="() => toggleMenu(true)"
			>
				<template #icon>
					<SvgIconMenu />
				</template>
			</BaseButton>

			<NuxtLinkExt v-else-if="onSearchPage" :to="previousPath">
				<BaseButton
					id="mobile-menu"
					dark
					class="w-48 h-48 flex-shrink-0"
					aria-label="Luk søgning"
				>
					<template #icon>
						<SvgIconClose />
					</template>
				</BaseButton>
			</NuxtLinkExt>
		</div>

		<div
			:class="[
				'fixed top-0 left-0 w-full h-full pointer-events-none',
				'bg-black bg-opacity-75 opacity-0',
				'duration-1000 ease-smooth-out',

				{
					'opacity-100': menuIsOpen,
				},
			]"
		></div>

		<!-- Burger Menu -->
		<Portal name="BurgerMenu" to="overlay">
			<Transition
				name="t-site-header__burger-menu"
				duration="1000"
				appear
			>
				<BurgerMenu
					v-if="menuIsOpen"
					key="BurgerMenu"
					@close="() => toggleMenu(false)"
				/>
			</Transition>
		</Portal>
	</header>
</template>

<script>
import { mapState } from 'vuex';

import SiteLogo from '~/components/subsite/SiteLogo';
import BurgerMenu from '~/components/subsite/BurgerMenu';
import LanguagePicker from '~/components/subsite/LanguagePicker';
import SiteHeaderSearch from '~/components/shared/SiteHeaderSearch';
import SearchLongread from '~/components/subsite/SearchLongread';
import SvgIconMenu from '~/assets/svgs/icon-menu.svg?inline';
import SvgIconClose from '~/assets/svgs/icon-close.svg?inline';

export default {
	name: 'SiteHeader',

	components: {
		SiteLogo,
		BurgerMenu,
		LanguagePicker,
		SiteHeaderSearch,
		SearchLongread,
		SvgIconMenu,
		SvgIconClose,
	},

	props: {
		keepAtTop: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		const { url, navigation, searchPage } = this.$store.state.site || {};
		const fallbackUrl = `/${
			this.$route.path.split('/').filter(Boolean).shift() || '/da/'
		}/`;

		return {
			url: url || fallbackUrl,
			navigation,
			searchPage,

			isSticky: false,
			intersectionObserver: null,
			searchHasFocus: false,

			menuIsOpen: false,
			searchMode: 'button', // 'button' or 'input'

			scrollLast: 0,
			scrollPercentage: 0,
			offset: 110,
		};
	},

	computed: {
		isFrontpage() {
			return this.$store.state.template === 'FrontPageSubPage';
		},

		searchHasInput() {
			return !!this.$refs.mobileSearch?.searchText;
		},

		writeOutNavigation() {
			return !this.$store.state.site.showAsMobile;
		},

		contentStyle() {
			const scalar = this.onSearchPage
				? this.scrollPercentage * this.offset
				: 0;

			const translate = `translate3d(0, -${scalar}px, 0)`;
			const transition = `transition: 300ms ease-out`;
			return `transform: ${translate}; ${
				!this.onSearchPage && transition
			}`;
		},

		buttonStyle() {
			const scalar = this.onSearchPage ? this.scrollPercentage * 95 : 0;

			const translate = `translate3d(0, ${scalar}px, 0)`;
			const transition = `transition: 300ms ease-out`;

			return `transform: ${translate}; ${
				!this.onSearchPage && transition
			}`;
		},

		...mapState({
			onSearchPage: (state) => state.template === 'SearchPageSubSite',
			previousPath: (state) => state.previousPath || state.site?.url,
		}),
	},

	watch: {
		onSearchPage() {
			this.$nextTick(() => {
				this.scrollPercentage = 0;
			});
		},
	},

	mounted() {
		this.intersectionObserver = new IntersectionObserver(
			(e) => {
				this.isSticky = !this.keepAtTop && !e?.[0]?.isIntersecting;
			},
			{
				rootMargin: '4px 0px 0px 0px',
				threshold: [1],
			}
		);

		this.intersectionObserver.observe(this.$el);
		window.addEventListener('scroll', this.onScroll);
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	beforeDestroy() {
		this.intersectionObserver?.disconnect?.();
		window.removeEventListener('scroll', this.onScroll);
		window.removeEventListener('resize', this.onResize);
	},

	methods: {
		onScroll() {
			if (this.onSearchPage) {
				const delta = window.scrollY - this.scrollLast;
				this.scrollLast = window.scrollY;

				this.scrollPercentage += delta / this.offset;
				this.scrollPercentage = Math.min(
					Math.max(0, this.scrollPercentage),
					1
				);
			}
		},

		onResize() {
			const desktop = window.innerWidth >= 768;
			this.offset = desktop ? 110 : 80;
		},

		onSearchFocus() {
			if (!this.isSticky || this.searchHasInput) {
				this.searchHasFocus = true;
			}
		},
		onSearchBlur(e) {
			const targetParent =
				e.target?.parentElement || e.relatedTarget?.parentElement;
			const relatedTargetParent = e.relatedTarget?.parentElement;
			if (targetParent !== relatedTargetParent) {
				this.searchHasFocus = false;
			}
		},

		toggleMenu(newState = !this.menuIsOpen) {
			this.menuIsOpen = newState;
		},
	},
};
</script>

<style lang="postcss">
.c-site-header {
	@apply relative w-full h-site-header z-40;
	@apply duration-500 ease-smooth-out;
}

.c-site-header__menu-separator {
	border-left: 1.5px solid currentColor;
	height: 16px;
	line-height: 16px;
	margin-bottom: -2px;

	&:last-child {
		display: none;
	}
}

.c-site-header__button .c-base-button__inner {
	@apply justify-center;
}

.c-site-header__bottom-bar:before,
.c-site-header__bottom-bar:after {
	@apply transition-opacity duration-500;
	content: '';
	z-index: -1;
}
.c-site-header__bottom-bar:before {
	@apply absolute left-0 bottom-0 w-full h-full;
	@apply bg-white opacity-0 pointer-events-none;
}
.c-site-header__bottom-bar:after {
	@apply absolute left-0 bottom-full w-full h-4xl;
	@apply opacity-0 pointer-events-none;
	background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%);
}
.c-site-header__bottom-bar--has-gradient:before,
.c-site-header__bottom-bar--has-gradient:after {
	@apply opacity-100;
}

.t-site-header__mobile-search-enter-active,
.t-site-header__mobile-search-leave-active {
	@apply duration-300;
}
.t-site-header__mobile-search-enter-active {
	@apply ease-smooth-out;
}
.t-site-header__mobile-search-leave-active {
	@apply ease-smooth-in;
}
.t-site-header__mobile-search-enter.direction-reverse,
.t-site-header__mobile-search-leave-to.direction-reverse {
	transform: translate3d(0, -80px, 0) !important;
}
.t-site-header__mobile-search-enter:not(.direction-reverse),
.t-site-header__mobile-search-leave-to:not(.direction-reverse) {
	transform: translate3d(0, 80px, 0) !important;
}

/* Burger menu transition */
.t-site-header__burger-menu-enter-active,
.t-site-header__burger-menu-leave-active {
	@apply duration-500 ease-smooth-out;
}

.t-site-header__burger-menu-enter,
.t-site-header__burger-menu-leave-to {
	transform: translateY(-100vh);
}
</style>
