<template>
	<span
		class="c-site-header-logo"
		:class="{
			'c-site-header-logo--typed': !logo,
		}"
	>
		<Component
			:is="to ? 'NuxtLinkExt' : 'span'"
			v-if="siteName || logo"
			class="relative inline-flex items-baseline"
			:class="logo ? 'h-full max-h-fit' : 'max-w-fit'"
			:to="to"
			:aria-label="to ? siteName : null"
			:tabindex="tabindex"
		>
			<UmbracoImage
				v-if="logo"
				class="c-site-header-logo__icon max-h-full"
				:style="`width: auto; height: ${logoHeight}px; aspect-ratio: ${
					Math.round((logo.width / logo.height) * 100) / 100
				}`"
				:widths="[
					Math.ceil(
						(logo.width / logo.height) *
							Math.round((maxHeight / 48) * 36)
					),
					Math.ceil((logo.width / logo.height) * maxHeight),
					maxWidth,
				]"
				:sizes="
					imageSizes({
						default: '34vw',
					})
				"
				:alt="logo.altText"
				:source-url="logo.cropUrl"
				:source-width="logo.width"
				:source-height="logo.height"
				:lazyload="false"
			/>
			<span
				v-else
				class="
					inline-block
					font-darker-grotesque font-bold
					text-logo
					uppercase
					transition-all
					duration-500
				"
				v-text="siteName"
			></span>
		</Component>
	</span>
</template>

<script>
import UmbracoImage, { imageSizes } from '@limbo-works/vue-umbraco-image';

export default {
	name: 'SiteLogo',

	components: {
		UmbracoImage,
	},

	props: {
		to: [String, Object],
		tabindex: {
			type: [String, Number],
			default: null,
		},
		maxWidth: {
			type: Number,
			default: 260,
		},
		maxHeight: {
			type: Number,
			default: 48,
		},
	},

	computed: {
		siteName() {
			return this.$store.state.site?.name;
		},
		logo() {
			return this.$store.state.site?.logo;
		},
		logoWidth() {
			if (this.logo?.width && this.logo?.height) {
				return Math.min(
					Math.round(
						(this.maxHeight / this.logo.height) * this.logo.width
					),
					this.maxWidth
				);
			}
			return this.maxWidth;
		},
		logoHeight() {
			if (this.logo?.width && this.logo?.height) {
				return Math.min(
					Math.round(
						(this.maxWidth / this.logo.width) * this.logo.height
					),
					this.maxHeight
				);
			}
			return this.maxHeight;
		},
	},

	methods: {
		imageSizes,
	},
};
</script>

<style lang="postcss">
.c-site-header-logo {
	@apply inline-flex justify-start items-center w-header-logo-width h-header-logo-height;
}
</style>
