var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-burger-menu relative bg-white h-full overflow-hidden"},[_c('Transition',{attrs:{"name":"t-burger-menu__flare","duration":1600,"appear":""}},[_c('LensFlare',{class:[
				'c-burger-menu__lens-flare',
				'absolute bottom-0 right-0',
				'<1024:top-0 <1024:bottom-auto',
				'w-1/2',
			],attrs:{"id":"c-burger-menu__flare","angle":_vm.flareAngle,"min-blur-amount":15,"max-blur-amount":15,"flares":[
				{
					size: 1000,
					offset: 300,
					opacity: 1,
					theme: 'primary',
					classes: [
						'c-burger-menu__flare-first',
						'duration-1000 ease-smooth-out',
					],
				},
				{
					size: 500,
					offset: -200,
					opacity: 0.8,
					theme: 'secondary',
					classes: [
						'c-burger-menu__flare-second',
						'duration-1000 ease-smooth-out',
					],
				},
			]}})],1),_vm._v(" "),_c('div',{class:[
			'relative max-w-layout-max',
			'mx-auto h-full overflow-y-scroll',
			'px-layout-margin pt-xl pb-md >=1024:pt-lg >=1024:pb-4xl',
			'flex flex-col justify-between',
		]},[_c('div',{staticClass:"relative hidden >=1024:block"},[_c('BaseButton',{staticClass:"absolute right-0",attrs:{"dark":""},on:{"click":_vm.closeOverlay},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s('LUK')}})]},proxy:true},{key:"icon",fn:function(){return [_c('SvgClose',{staticClass:"w-14"})]},proxy:true}])})],1),_vm._v(" "),_c('div',{class:[
				'relative h-full',
				'flex >=1024:grid >=1024:grid-cols-12',
				'gap-x-layout-gutter >=1024:mt-3xl',
				'duration-500 ease-smooth-out',
			]},[_c('div',{ref:"parentItems",class:[
					'w-5/5col h-full',
					'>=1024:block >=1024:relative >=1024:w-auto',
					'>=1024:col-start-1 >=1024:col-end-6',
				]},[_c('div',{staticClass:"h-6xl",staticStyle:{"max-height":"10vh"}}),_vm._v(" "),_c('ul',{staticClass:"space-y-lg"},_vm._l((_vm.main),function(item,index){return _c('li',{key:`main-item-${index}`},[_c('NuxtLinkExt',{class:[
								'flex items-center',
								'text-menu-category font-darker-grotesque',
								'duration-500 ease-smooth-out',
								'transform group text-left',

								{
									'opacity-60 hover:opacity-100':
										_vm.activeItem &&
										_vm.activeItem.id !== item.id,
								},
							],attrs:{"to":item.url}},[_c('BurgerMenuArrow',{staticClass:"flex-shrink-0",attrs:{"active":_vm.activeItem && _vm.activeItem.id === item.id}}),_vm._v(" "),_c('span',{class:[
									'mb-6 transform',
									'transition-transform duration-500 ease-smooth-out',

									{
										'translate-x-20':
											_vm.activeItem &&
											_vm.activeItem.id === item.id,
										'group-hover:translate-x-20':
											!_vm.activeItem ||
											_vm.activeItem.id !== item.id,
									},
								],domProps:{"innerHTML":_vm._s(item.title)}})],1)],1)}),0),_vm._v(" "),_c('div',{staticClass:"h-6xl",staticStyle:{"max-height":"10vh"}})])]),_vm._v(" "),_c('div',{class:[
				'c-burger-menu__bottom',
				'grid grid-cols-1 gap-sm max-w-fit mt-2xl',
				'>=1024:flex >=1024:flex-wrap >=1024:w-5/12col',
			]},[_vm._l((_vm.computedSecondary),function(item,index){return _c('NuxtLinkExt',{key:`secondary-${index}`,attrs:{"to":item.url,"target":item.target}},[_c('BaseButton',{staticClass:"<1024:h-48 <1024:min-w-full",attrs:{"dark":!item.isExternal,"light":item.isExternal},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"mb-1 >=1024:mb-0",domProps:{"textContent":_vm._s(item.title || item.name)}})]},proxy:true},{key:"icon",fn:function(){return [(item.isExternal)?_c('SvgArrow',{staticClass:"transform -rotate-45"}):_vm._e()]},proxy:true}],null,true)})],1)}),_vm._v(" "),_c('BaseButton',{class:[
					'c-burger-menu__close-mobile >=1024:hidden',
					'fixed bottom-md right-layout-margin',
				],attrs:{"dark":"","aria-label":"Luk menu"},on:{"click":_vm.closeOverlay},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgClose',{staticClass:"w-14"})]},proxy:true}])})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }