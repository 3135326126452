var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"c-site-header-logo",class:{
		'c-site-header-logo--typed': !_vm.logo,
	}},[(_vm.siteName || _vm.logo)?_c(_vm.to ? 'NuxtLinkExt' : 'span',{tag:"Component",staticClass:"relative inline-flex items-baseline",class:_vm.logo ? 'h-full max-h-fit' : 'max-w-fit',attrs:{"to":_vm.to,"aria-label":_vm.to ? _vm.siteName : null,"tabindex":_vm.tabindex}},[(_vm.logo)?_c('UmbracoImage',{staticClass:"c-site-header-logo__icon max-h-full",style:(`width: auto; height: ${_vm.logoHeight}px; aspect-ratio: ${
				Math.round((_vm.logo.width / _vm.logo.height) * 100) / 100
			}`),attrs:{"widths":[
				Math.ceil(
					(_vm.logo.width / _vm.logo.height) *
						Math.round((_vm.maxHeight / 48) * 36)
				),
				Math.ceil((_vm.logo.width / _vm.logo.height) * _vm.maxHeight),
				_vm.maxWidth,
			],"sizes":_vm.imageSizes({
					default: '34vw',
				}),"alt":_vm.logo.altText,"source-url":_vm.logo.cropUrl,"source-width":_vm.logo.width,"source-height":_vm.logo.height,"lazyload":false}}):_c('span',{staticClass:"inline-block font-darker-grotesque font-bold text-logo uppercase transition-all duration-500",domProps:{"textContent":_vm._s(_vm.siteName)}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }